import './App.css';
import Form from './form'
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className=' pt-4'>
      <Form/>
    </div>
  );
}

export default App;
