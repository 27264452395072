import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


function Form() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setName('');
        setEmail('');
        setMobile('');

        const formData = {
            name: name,
            email: email,
            mobile: mobile,
        };

        try {
            // const response = await fetch('http://localhost/studentregistrationPHP/form_submission.php', {
                const response = await fetch('https://vendor.thedatatechlabs.com/studentregistrationPHP/form_submission.php', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),

            });
            console.log(formData)
            if (response.status === 200) {
                const data = await response.json();
                if (data.success === true) {
                    console.log('Data submitted successfully:', data.message);
                    alert("Submitted Successfully");

                } else {
                    console.log('Error:', data.message);
                    alert(data.message);


                }
            } else {
                console.log('Error:', response.status);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    return (
        <div>
            <div className='App p-3'>
                <h1 className="text-primary">Student Registration Form</h1>
            </div>
            <div className='d-flex justify-content-center'>
                <form onSubmit={handleSubmit}>

                    <div className="form-group row ">
                        <div className='pt-4  col-sm-6'>
                            <label className='fs-5 fw-bold'>Name :</label>
                        </div>
                        <div className="pt-2 col-sm-10">
                            <input className="form-control" type="text"
                            placeholder="Enter Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row pt-3">
                        <div className='pt-4 col-sm-6'>
                            <label className='fs-5 fw-bold'>Email address :</label>
                        </div>
                        <div className="pt-2 col-sm-10">
                            <input className="form-control" placeholder="name@example.com" type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>

                    <div className=" row pt-3">
                        <div className='pt-4 col-sm-6'>
                            <label className='fs-5 fw-bold'>Contact number :</label>
                        </div>
                        <div className="pt-2 col-sm-10">
                            <input className="form-control" placeholder="Enter Contact number" type="tel"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)} />
                        </div>
                    </div>
                    <div className="  form-group row pt-5 d-flex justify-content-center">
                        <div className="col-sm-5">
                            <button type="submit" className="btn btn-lg btn-success">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            ``

        </div>
    );
}


export default Form;
